import Script from 'next/script';
import { RailsUserConfiguration } from '~/typings/services/rails/session';

const liveChatEnabledWidgetSettings = JSON.stringify({
  webWidget: {
    chat: {
      suppress: false,
      tags: ['vip'],
      notifications: {
        mobile: {
          disable: true,
        },
      },
    },
  },
});
const liveChatEnabledHtml = {
  __html: `window.zESettings = ${liveChatEnabledWidgetSettings};`,
};

function ZenDeskWidgetScript({
  loggedIn,
  route,
  userConfiguration,
}: {
  loggedIn: boolean;
  route: string;
  userConfiguration?: RailsUserConfiguration;
}) {
  if (
    loggedIn &&
    route !== '/checkout' &&
    (userConfiguration?.pro_plus_seller ||
      userConfiguration?.features?.find(f => f.slug === 'live_chat'))
  ) {
    return (
      <>
        <Script
          type="text/javascript"
          id="zendesk-config"
          dangerouslySetInnerHTML={liveChatEnabledHtml}
        />

        <Script
          strategy="afterInteractive"
          id="ze-snippet"
          type="text/javascript"
          src="https://static.zdassets.com/ekr/snippet.js?key=fb735c1c-bdc2-4b89-a1d3-a197e621b157"
        />
      </>
    );
  }

  return null;
}

export default ZenDeskWidgetScript;
