import { DeviceContext } from 'common-nextjs';
import React from 'react';
import { ThemeProvider } from '~/components/Theme';
import { ChromelessContext } from '~/contexts/ChromelessContext';
import ModalsContextProvider from '~/contexts/ModalsContext';
import SessionContainer from '~/contexts/SessionContext';
import { SwapPopoutProvider } from '~/contexts/SwapPopoutContext';
import { InitialData } from '~/lib/initialData/initialData';
import { AbTestContext } from './AbTestContext';
import SaleContainer from './SaleContext';
import { ServerSideCookiesContext } from './ServerSideCookies';

interface Props extends Omit<InitialData, 'pageProps'> {}

const GlobalContexts: React.FC<React.PropsWithChildren<Props>> = ({
  abTests,
  children,
  chromeless,
  device,
  initialSessionState,
  sales,
  serverSideCookies,
}) => (
  <ThemeProvider>
    <ServerSideCookiesContext value={serverSideCookies || {}}>
      <DeviceContext value={device}>
        <SessionContainer initialState={{ session: initialSessionState }}>
          <SaleContainer initialState={sales}>
            <ChromelessContext value={chromeless}>
              <ModalsContextProvider initialState={{ modals: [] }}>
                <AbTestContext value={abTests}>
                  <SwapPopoutProvider>{children}</SwapPopoutProvider>
                </AbTestContext>
              </ModalsContextProvider>
            </ChromelessContext>
          </SaleContainer>
        </SessionContainer>
      </DeviceContext>
    </ServerSideCookiesContext>
  </ThemeProvider>
);

export default GlobalContexts;
