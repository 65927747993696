import React from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
// noinspection ES6UnusedImports

export const theme = {
  breakpoints: [
    // 0, // zero represents the default (for mobile-first approach)
    '48em',
    '1440px',
  ],
  colors: {
    black: '#253C32',
    blue: '#253C32',
    green: '#02c874',
    darkgrey: '#4a5568',
    orange: '#fcb300',
    red: '#e84f4f',
    white: '#fff',
    grey: '#CFDCD6',
    yellow: '#ffce54',

    footer: {
      bg: '#f4f4f4',
    },
  },
  fontSizes: [
    '0.75rem',
    '0.875rem',
    '1rem',
    '1.5rem',
    '2rem',
    '2.5rem',
    '3rem',
    96,
    128,
  ],
  fontWeights: {
    normal: 400,
    semibold: 600,
    bold: 700,
  },
  shadows: ['0 2px 4px rgba(0, 0, 0, .15)', '0 2px 4px rgba(0, 0, 0, .5)'],
  space: [0, 8, 16, 32, 48, 64, 128],
};

export const ThemeProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => (
  // @ts-ignore
  (<StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>)
);
 
