import { themeGet } from '@styled-system/theme-get';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  //html { height: 100%; overflow:auto; }

  body {
    color: ${themeGet('colors.black')};
    background-color: #F8FFFC;
    transition: left 0.3s, right 0.3s;
    line-height: 1.34;
    font-weight: 400;
  }
  
  /* purgecss start ignore */
  body.nav-open {
    overflow: hidden;
    height: 100%;
  }
  
  #__next {
    background-color: #fff;
    transition: left 0.3s, right 0.3s;
    position: relative;
    //height: 100vh;
    left: 0;
    right: 0;
  }
  
  body.nav-open.left #__next {
    left: 270px;
    right: -270px;
    position: fixed;
  }
  
  body.nav-open.right #__next {
    left: -270px;
    right: 270px;
    position: fixed;
  }
  /* purgecss end ignore */
  
  a {
    text-decoration: none;
  }
  
  .grecaptcha-badge {
    visibility: collapse;
  }
  
  /* Make clicks pass-through */
  #nprogress {
    pointer-events: none;
    position: relative;
    z-index: 1030;
  }
  
  #nprogress .bar {
    background: ${themeGet('colors.green')};
  
    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;
  
    width: 100%;
    height: 4px;
    border-bottom-right-radius: 2px;
  
    transition: all 0.3s;
  }
  
  /* Fancy blur effect */
  #nprogress .peg {
    display: block;
    position: absolute;
    right: 0;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px ${themeGet('colors.green')}, 0 0 5px ${themeGet(
      'colors.green',
    )};
    opacity: 1;
  
    -webkit-transform: rotate(3deg) translate(0px, -4px);
    -ms-transform: rotate(3deg) translate(0px, -4px);
    transform: rotate(3deg) translate(0px, -4px);
  
    transition: all 0.3s;
  }
  
  .nav-scale-fade-in-enter {
    transform: scale(0.95);
    opacity: 0.01;
    display: block;
  }
  
  /* .nav-scale-fade-in-enter.nav-scale-fade-in-enter-active */
  .nav-scale-fade-in-enter-active {
    transform: scale(1);
    opacity: 1;
    display: block;
    transition: transform 0.3s, opacity 0.3s;
  }
  
  .nav-scale-fade-in-exit {
    transform: scale(1);
    opacity: 1;
    display: block;
  }
  
  .nav-scale-fade-in-exit-active {
    transform: scale(0.95);
    opacity: 0.01;
    display: block;
    transition: transform 0.3s, opacity 0.3s;
  }
  
  .nav-scale-fade-in-enter-done {
    display: block;
  }
  
  .nav-scale-fade-in-exit-done {
    display: none;
  }
`;

export default GlobalStyle;

// For the NProgress spinner, add this block to the global style
/*
#nprogress .spinner {
  display: block;

  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #02c874;
  border-left-color: #02c874;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
  -webkit-transform: rotate(0deg);
}
  100% {
  -webkit-transform: rotate(360deg);
}
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
}
  100% {
    transform: rotate(360deg);
}
}*/
