import { useQuery } from '@tanstack/react-query';
import { addDays, isWithinInterval } from 'date-fns';
import React, { ReactNode, useContext } from 'react';
import { fetchSales } from '~/api/configuration';
import { useSession } from '~/contexts/SessionContext';
import useKnownCookie from '~/hooks/useKnownCookie';
import { useV2Session } from '~/hooks/useV1Session';
import { minutes5inMS } from '../constants/times';

type SaleContextType = {
  dismiss: () => void;
  isBuySide: boolean;
  isSellSide: boolean;
  sale: any; // Replace 'any' with your actual sale type
  showBanner: boolean;
} | null;

export type SaleInitialState = {
  dismissed?: boolean;
};

const SaleContext = React.createContext<SaleContextType>(null);

export function useSale() {
  const context = useContext(SaleContext);
  if (!context) {
    throw new Error('useSale must be used within a SaleProvider');
  }
  return context;
}

type SaleProviderProps = {
  children: ReactNode;
  initialState?: SaleInitialState;
};

export default function SaleContextProvider({
  children,
  initialState = {},
}: SaleProviderProps) {
  const { data: sales } = useQuery(['sales'], fetchSales, {
    staleTime: minutes5inMS,
  });
  const [dismissedCookie, setDismissedCookie] =
    useKnownCookie('dismissed_sale');

  const { loggedIn, user } = useSession();

  const sale = sales?.[0];

  let showBanner = false;

  const isBuySide = sale
    ? isWithinInterval(Date.now(), {
        start: new Date(sale.started_at),
        end: new Date(sale.ended_at),
      })
    : false;
  const isSellSide = sale
    ? isWithinInterval(Date.now(), {
        start: new Date(sale.opened_at),
        end: new Date(sale.ended_at),
      })
    : false;

  const { data: v2Session } = useV2Session(isSellSide);

  function dismiss() {
    setDismissedCookie('1', {
      expires: addDays(Date.now(), 3),
      path: '/',
    });
  }

  if (!sale) {
    return (
      <SaleContext
        value={{
          sale: null,
          dismiss: () => null,
          showBanner: false,
          isBuySide: false,
          isSellSide: false,
        }}
      >
        {children}
      </SaleContext>
    );
  }

  if (dismissedCookie || initialState.dismissed) {
    showBanner = false;
  } else if (isBuySide) {
    showBanner = true;
  } else if (isSellSide) {
    if (loggedIn && user) {
      showBanner = !!v2Session?.actions.includes('view_sale_banner');
    }
  }

  return (
    <SaleContext
      value={{
        dismiss,
        isBuySide,
        isSellSide,
        sale,
        showBanner,
      }}
    >
      {children}
    </SaleContext>
  );
}
