import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { getEnv, ReactQueryAppContext, ScrollRestoration } from 'common-nextjs';
import { KnownCookie } from 'common-types';
import Cookies from 'js-cookie';
import { AppContext, AppProps } from 'next/app';
import { Roboto } from 'next/font/google';
import { Router } from 'next/router';
import NProgress from 'nprogress';
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import ClientSideAppLoads from '~/components/Chrome/ClientSideAppLoads';
import ErrorBoundary from '~/components/ErrorBoundary';
import MetaAppIcons from '~/components/Icons/MetaAppIcons';
import AttentiveScript from '~/components/ThirdPartyScripts/AttentiveScript';
import ImpactTracker from '~/components/ThirdPartyScripts/ImpactTracker';
import RedditScript from '~/components/ThirdPartyScripts/RedditScript';
import TikTokScript from '~/components/ThirdPartyScripts/TikTokScript';
import { WebVitals } from '~/components/WebVitals';
import GlobalContexts from '~/contexts/GlobalContexts';
import getInitialClientProps from '~/lib/initialData/getInitialClientProps';
import { InitialData } from '~/lib/initialData/initialData';
import analyticsInitialize from '~/services/analytics/analyticsInitialize';
import analyticsPageView from '~/services/analytics/analyticsPageView';
import { getSentry } from '~/services/sentry/wrapper';
import { reduxWrapper } from '~/store/store';
import GlobalStyle from '~/theme/GlobalStyle';
import '../styles/index.css';

const roboto = Roboto({
  weight: ['400', '500', '700'],
  style: ['normal', 'italic'],
  subsets: ['latin'],
  display: 'swap',
});

if (typeof document !== 'undefined') {
  getSentry()?.setExtra('client_id', Cookies.get('sls_client_id'));

  NProgress.configure({
    minimum: 0.2,
    showSpinner: false,
  });

  analyticsInitialize();
  analyticsPageView(window.location.pathname + window.location.search);
}

function initRouterEvents() {
  Router.events.on('routeChangeStart', () => NProgress.start());
  Router.events.on('routeChangeComplete', (url: string) => {
    NProgress.done();
    analyticsPageView(url);
  });
  Router.events.on('routeChangeError', () => NProgress.done());
}

async function setupDevWorker() {
  // if (getEnv() === 'development' && typeof document === 'undefined') {
  //   const { mockDevServer } = await import('../test/dev-server');
  //   await mockDevServer.listen();
  // }
  if (getEnv() === 'development' && typeof document !== 'undefined') {
    const { worker } = await import('../test/dev-worker');
    await worker.start({
      onUnhandledRequest: 'bypass',
    });
  }
}

// setupDevWorker();

type MyAppComponent = React.FC<AppProps & InitialData> & {
  getInitialProps: (ctx: AppContext) => Promise<InitialData>;
};

const MyApp: MyAppComponent = ({ Component, ...rest }) => {
  useEffect(() => {
    initRouterEvents();

    // Add client ID on the client as well, in case the initial document response was cached
    if (!Cookies.get('sls_client_id' as KnownCookie)) {
      Cookies.set('sls_client_id' as KnownCookie, uuidv4());
    }
  }, []);

  const { store, props } = reduxWrapper.useWrappedStore(rest);
  const { pageProps, dehydratedState, dehydratedSharedQueryClient } = props;

  return (
    <ErrorBoundary fallback={<div>Something went wrong</div>}>
      <MetaAppIcons />

      <style jsx global>{`
        html,
        body {
          font-family: ${roboto.style.fontFamily};
        }
      `}</style>

      <Provider store={store}>
        <ReactQueryAppContext
          pageProps={pageProps}
          dehydratedSharedState={dehydratedSharedQueryClient}
          dehydratedState={dehydratedState}
        >
          <ReactQueryDevtools buttonPosition="bottom-left" />
          <GlobalContexts {...props}>
            <ScrollRestoration />
            <Component {...pageProps} />
            <ImpactTracker />
            <TikTokScript />
            <RedditScript />
            <AttentiveScript />

            <GlobalStyle />
            <ClientSideAppLoads />
          </GlobalContexts>
        </ReactQueryAppContext>
      </Provider>

      <WebVitals />
    </ErrorBoundary>
  );
};

MyApp.getInitialProps = async (appCtx: AppContext) => {
  console.log(appCtx.ctx.asPath);
  if (appCtx.ctx.req) {
    return (await import('~/lib/initialData/getInitialServerProps')).default(
      appCtx,
    );
  } else {
    return getInitialClientProps(appCtx);
  }
};

export default MyApp;
