export const sentryIgnoreErrors = [
  // Random plugins/extensions
  'top.GLOBALS',
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  'originalCreateNotification',
  'canvas.contentDocument',
  'MyApp_RemoveAllHighlights',
  'http://tt.epicplay.com',
  "Can't find variable: ZiteReader",
  'jigsaw is not defined',
  'ComboSearch is not defined',
  'http://loading.retry.widdit.com/',
  'atomicFindClose',
  // Facebook borked
  'fb_xd_fragment',
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
  'bmi_SafeAddOnload',
  'EBCallBackMessageReceived',
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  'conduitPage',
  // Generic error code from errors outside the security sandbox
  // You can delete this if using raven.js > 1.0, which ignores these automatically.
  'Script error.',

  // Added by us to stop people's bad connections creating noise
  'Failed to fetch',
  'The network connection was lost.',

  // When we get to a better PWA state, bring these back
  'The request timed out.',
  'cancelled',
  'Network request failed',
  'The Internet connection appears to be offline.',
  'Preflight response is not successful',

  "Failed to execute 'postMessage' on 'Window'",
  'androidInterface',
  'Blocked a frame with origin',
  'ampproject',
  'ResizeObserver',
  /window\.top\.document/,
  /Illegal invocation/i,
  /Tapad/,
  /Redirected/,
  /pageFold/,
  /_AutofillCallbackHandler/,
];

export const sentryDenyUrls = [
  // Facebook flakiness
  /graph\.facebook\.com/i,
  // Facebook blocked
  /connect\.facebook\.net\/en_US\/all\.js/i,
  // Woopra flakiness
  /eatdifferent\.com\.woopra-ns\.com/i,
  /static\.woopra\.com\/js\/woopra\.js/i,
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  // Other plugins
  /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
  /webappstoolbarba\.texthelp\.com\//i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  // Ignore Google flakiness
  /\/(gtm|ga|analytics)\.js/i,
  /intercom/i,
  /yahoo\.com/i,
  /pub\.network/i,
  /freestar/i,
  /static\.criteo\.net\/js\/connect\.js/i,
  /static\.yieldmo\.com/i,
  /ajs-assets\.ftstatic\.com/i,
  /cdn\.flashtalking\.com/i,
];
