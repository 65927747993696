'use client';

import produce from 'immer';
import React, { createContext, ReactNode, useContext, useState } from 'react';
import { CSSProp } from 'styled-components';
import { Modals, ModalType } from '~/components/Modal/ModalsGroup';
import { getSentry } from '~/services/sentry/wrapper';

export interface ModalsValue {
  closeModal: (type?: number | ModalType) => void;
  modals: ModalDescriptor[];
  openModal: <Type extends ModalType>(
    type: Type,
    props: ModalPropsArgument<Type>,
    component?: ModalDescriptor['component'],
  ) => void;
  deleteModal: (id: number) => void;
  setModal: <T extends ModalType>(
    type: T,
    props: Partial<ModalPropsArgument<T>>,
  ) => void;
}

export interface ModalStyleProps {
  modalOverlayCss?: CSSProp;
  modalBodyCss?: CSSProp;
  modalWidth?: number;
  hideCloseButton?: boolean;
}

export interface ModalDescriptor {
  id: number;
  open: boolean;
  type: ModalType;
  props:
    | undefined
    | Record<
        | 'modalOverlayCss'
        | 'modalBodyCss'
        | 'hideCloseButton'
        | 'overrideAnimationClasses'
        | string,
        any
      >;
  component?: (onRequestClose: () => void) => React.ReactNode;
}

export interface ModalsState {
  modals: ModalDescriptor[];
}

type ModalPropsArgument<T extends ModalType> = Modals[T] & ModalStyleProps;

const ModalsContext = createContext<ModalsValue | undefined>(undefined);

interface ModalsProviderProps {
  children: ReactNode;
  initialState?: ModalsState;
}

export default function ModalsContextProvider({
  children,
  initialState,
}: ModalsProviderProps) {
  const [modals, setModals] = useState<ModalDescriptor[]>(
    initialState?.modals ?? [],
  );

  function openModal<Type extends ModalType>(
    type: Type,
    props: ModalPropsArgument<Type>,
    component?: ModalDescriptor['component'],
  ) {
    window.zE?.('webWidget', 'hide');
    getSentry()?.addBreadcrumb({
      message: `opened modal '${type}'`,
      data: props,
    });
    setModals(
      produce(state => {
        state.push({
          component,
          type,
          props: props ?? {},
          id: Date.now(),
          open: true,
        });
      }),
    );
  }

  function closeModal(typeOrId?: number | ModalType) {
    getSentry()?.addBreadcrumb({ message: `closed modal '${typeOrId}'` });
    window.zE?.('webWidget', 'show');
    setModals(
      produce(state => {
        if (typeOrId) {
          let index;
          if (typeof typeOrId === 'number') {
            index = state.findIndex(m => m.id === typeOrId);
          } else {
            index = state.findIndex(m => m.type === typeOrId);
          }

          if (index >= 0) {
            state[index].open = false;
          }
        } else {
          state[state.length - 1].open = false;
        }
      }),
    );
  }

  function setModal<T extends ModalType>(
    type: T,
    props: Partial<ModalPropsArgument<T>>,
  ) {
    setModals(state => state.map(m => (m.type === type ? { ...m, props } : m)));
  }

  function deleteModal(id: number) {
    setModals(state => state.filter(m => m.id !== id));
  }

  const value: ModalsValue = {
    closeModal,
    modals,
    openModal,
    deleteModal,
    setModal,
  };

  return <ModalsContext value={value}>{children}</ModalsContext>;
}

export function useModals() {
  const context = useContext(ModalsContext);
  if (context === undefined) {
    throw new Error('useModals must be used within a ModalsProvider');
  }
  return context;
}
