import qs, { IStringifyOptions } from 'qs';

export function qsParse<Q extends Record<string, any>>(
  query?: string | URLSearchParams | URL | null,
  options: qs.IParseOptions = {},
): Q {
  if (!query) {
    // @ts-ignore
    return {};
  }

  let actualQuery: string;
  if (typeof query === 'string') {
    if (query[0] === '?') {
      actualQuery = query.slice(1);
    } else {
      actualQuery = query;
    }
  } else if (query instanceof URLSearchParams) {
    actualQuery = query.toString();
  } else if (query instanceof URL) {
    actualQuery = query.searchParams.toString();
  } else {
    // Unsafe fallback
    actualQuery = String(query);
  }

  return qs.parse(actualQuery, {
    // @ts-ignore
    arrayFormat: 'brackets',
    skipNulls: true,
    ...options,
  }) as Q;
}

/**
 * Returns a query string prepended by a "?"
 */
export function qsStringify<T extends Record<string, any>>(
  query?: T | null,
  options: qs.IStringifyOptions = {},
) {
  if (!query) {
    return '';
  }

  return qs.stringify(query, {
    addQueryPrefix: true,
    arrayFormat: 'brackets',
    skipNulls: true,
    ...options,
  });
}

/**
 * Takes strings or objects to return a combined query string
 */
export function qsUpdateString(
  input: (
    | string
    | null
    | undefined
    | Record<string, any>
    | URLSearchParams
    | URL
  )[],
  options?: IStringifyOptions,
): string {
  const combined = input.reduce<Record<string, any>>((prev, cur) => {
    if (
      typeof cur === 'string' ||
      cur instanceof URLSearchParams ||
      cur instanceof URL
    ) {
      return {
        ...prev,
        ...qsParse(cur),
      };
    } else {
      return {
        ...prev,
        ...cur,
      };
    }
  }, {});

  return qsStringify(combined, options);
}
